exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-page-tsx": () => import("./../../../src/pages/aboutPage.tsx" /* webpackChunkName: "component---src-pages-about-page-tsx" */),
  "component---src-pages-consulting-page-tsx": () => import("./../../../src/pages/consultingPage.tsx" /* webpackChunkName: "component---src-pages-consulting-page-tsx" */),
  "component---src-pages-contact-page-tsx": () => import("./../../../src/pages/contactPage.tsx" /* webpackChunkName: "component---src-pages-contact-page-tsx" */),
  "component---src-pages-description-page-tsx": () => import("./../../../src/pages/descriptionPage.tsx" /* webpackChunkName: "component---src-pages-description-page-tsx" */),
  "component---src-pages-gdpr-page-tsx": () => import("./../../../src/pages/gdprPage.tsx" /* webpackChunkName: "component---src-pages-gdpr-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offer-badanie-bakteriologiczne-tsx": () => import("./../../../src/pages/offer/badanieBakteriologiczne.tsx" /* webpackChunkName: "component---src-pages-offer-badanie-bakteriologiczne-tsx" */),
  "component---src-pages-offer-badanie-mykologiczne-tsx": () => import("./../../../src/pages/offer/badanieMykologiczne.tsx" /* webpackChunkName: "component---src-pages-offer-badanie-mykologiczne-tsx" */),
  "component---src-pages-offer-grzybica-paznokci-tsx": () => import("./../../../src/pages/offer/grzybicaPaznokci.tsx" /* webpackChunkName: "component---src-pages-offer-grzybica-paznokci-tsx" */),
  "component---src-pages-offer-grzybica-stop-tsx": () => import("./../../../src/pages/offer/grzybicaStop.tsx" /* webpackChunkName: "component---src-pages-offer-grzybica-stop-tsx" */),
  "component---src-pages-offer-main-page-tsx": () => import("./../../../src/pages/offerMainPage.tsx" /* webpackChunkName: "component---src-pages-offer-main-page-tsx" */),
  "component---src-pages-offer-nadpotliwosc-tsx": () => import("./../../../src/pages/offer/nadpotliwosc.tsx" /* webpackChunkName: "component---src-pages-offer-nadpotliwosc-tsx" */),
  "component---src-pages-offer-odciski-brodawki-tsx": () => import("./../../../src/pages/offer/odciskiBrodawki.tsx" /* webpackChunkName: "component---src-pages-offer-odciski-brodawki-tsx" */),
  "component---src-pages-offer-onycholiza-tsx": () => import("./../../../src/pages/offer/onycholiza.tsx" /* webpackChunkName: "component---src-pages-offer-onycholiza-tsx" */),
  "component---src-pages-offer-pedicure-medyczny-tsx": () => import("./../../../src/pages/offer/pedicureMedyczny.tsx" /* webpackChunkName: "component---src-pages-offer-pedicure-medyczny-tsx" */),
  "component---src-pages-offer-piety-tsx": () => import("./../../../src/pages/offer/piety.tsx" /* webpackChunkName: "component---src-pages-offer-piety-tsx" */),
  "component---src-pages-offer-przed-wizyta-tsx": () => import("./../../../src/pages/offer/przedWizyta.tsx" /* webpackChunkName: "component---src-pages-offer-przed-wizyta-tsx" */),
  "component---src-pages-offer-stopa-cukrzycowa-tsx": () => import("./../../../src/pages/offer/stopaCukrzycowa.tsx" /* webpackChunkName: "component---src-pages-offer-stopa-cukrzycowa-tsx" */),
  "component---src-pages-offer-wrastajace-wkrecajace-paznokcie-tsx": () => import("./../../../src/pages/offer/wrastajaceWkrecajacePaznokcie.tsx" /* webpackChunkName: "component---src-pages-offer-wrastajace-wkrecajace-paznokcie-tsx" */),
  "component---src-pages-offer-zanokcica-tsx": () => import("./../../../src/pages/offer/zanokcica.tsx" /* webpackChunkName: "component---src-pages-offer-zanokcica-tsx" */),
  "component---src-pages-pricing-page-tsx": () => import("./../../../src/pages/pricingPage.tsx" /* webpackChunkName: "component---src-pages-pricing-page-tsx" */),
  "component---src-pages-terms-of-service-page-tsx": () => import("./../../../src/pages/termsOfServicePage.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-page-tsx" */),
  "component---src-pages-vouchers-page-tsx": () => import("./../../../src/pages/vouchersPage.tsx" /* webpackChunkName: "component---src-pages-vouchers-page-tsx" */)
}

